import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'

const DemoreelsPage = () => (
  <Layout>
    <StaticQuery
      query={graphql`
        query DemoreelsQuery {
          site {
            siteMetadata {
              demoreels
            }
          }
        }
      `}
      render={data => (
        <ul className="unstyled-list project-list">
          {data.site.siteMetadata.demoreels.map(link_id => (
            <li key={link_id} className="demoreel-project-list-item">
              <div className="video-wrapper">
                <iframe
                  title={link_id}
                  allowFullScreen
                  frameBorder="0"
                  height="360"
                  width="640"
                  src={`https://player.vimeo.com/video/${link_id}`}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    />
  </Layout>
)

export default DemoreelsPage